@import url(https://fonts.googleapis.com/css?family=Alice|Roboto:300,500);
/**	
 * IMPORTS 
 */
/* RESET */
article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block;
}

audio, canvas, video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

html {
  margin: 0;
  padding: 0;
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
}

body, button, input, select, textarea {
  font-family: sans-serif;
  color: #222;
}

::-moz-selection {
  background: #ccc;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #ccc;
  color: #fff;
  text-shadow: none;
}

a {
  text-decoration: none;
  outline: none;
}

a:focus {
  outline: none;
}

a:hover, a:active {
  outline: none;
}

p {
  margin: 0 0 1em 0;
}

p:last-of-type {
  margin: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

pre, code, kbd, samp {
  font-family: monospace, monospace;
  _font-family: "courier new", monospace;
}

code {
  display: inline-block;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 85%;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

strong {
  font-weight: normal;
}

ul, ol {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  margin: 0 0 0 0;
  font-size: 100%;
}

ul, li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

dd {
  margin: 0;
}

nav ul, nav ol {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
}

legend {
  border: 0;
  *margin-left: -7px;
  padding: 0;
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}

button, input {
  line-height: normal;
  *overflow: visible;
}

table button, table input {
  *overflow: auto;
}

button, input[type=button], input[type=reset], input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

input:invalid, textarea:invalid {
  background-color: #f0dddd;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td {
  vertical-align: top;
}

html {
  font-size: 62.5%;
}

@media screen and (min-width: 320px) {
  head {
    font-family: "mobile 320px";
  }
  body:after {
    content: "mobile - min-width: 320px";
  }
}
@media screen and (min-width: 480px) {
  head {
    font-family: "mobileLandscape 480px";
  }
  body:after {
    content: "mobileLandscape - min-width: 480px";
  }
}
@media screen and (min-width: 768px) {
  head {
    font-family: "tablet 768px";
  }
  body:after {
    content: "tablet - min-width: 768px";
  }
}
@media screen and (min-width: 1024px) {
  head {
    font-family: "desktop 1024px";
  }
  body:after {
    content: "desktop - min-width: 1024px";
  }
}
@media screen and (min-width: 1200px) {
  head {
    font-family: "wide 1200px";
  }
  body:after {
    content: "wide - min-width: 1200px";
  }
}
@media screen and (min-width: 1400px) {
  head {
    font-family: "xwide 1400px";
  }
  body:after {
    content: "xwide - min-width: 1400px";
  }
}
head {
  clear: both;
}
head title {
  font-family: "mobile 320px, mobileLandscape 480px, tablet 768px, desktop 1024px, wide 1200px, xwide 1400px";
}

body:after {
  display: none;
}

*[data-usn-if] {
  display: none;
}

.browserdetect {
  width: 100%;
  display: none;
  padding: 40px 20px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  font-family: helvetica, sans-serif;
  color: #333;
}
.browserdetect strong {
  font-weight: bold;
  font-size: 120%;
}
.no-js .browserdetect, .no-backgroundsize .browserdetect, .no-mq .browserdetect {
  display: block;
}
.browserdetect.browserdetect--hidden {
  display: none !important;
}

.browserdetect--center {
  text-align: center;
}

.browserdetect__content {
  display: block;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.browserdetect__close {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 30px;
  font-size: 3rem;
  color: #333;
}
.browserdetect__close:hover, .browserdetect__close:focus {
  color: red;
}

.browserdetect__list {
  display: block;
  margin-top: 40px;
  text-align: center;
}
.browserdetect__list li {
  display: inline-block;
  width: 64px;
  height: 64px;
  margin: 0 20px;
}
.browserdetect__list a {
  display: block;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 0 0;
}
.browserdetect__list a.browserdetect__ie {
  background-image: url("/images/browserdetect/ie-64x64.png");
}
.browserdetect__list a.browserdetect__firefox {
  background-image: url("/images/browserdetect/ff-64x64.png");
}
.browserdetect__list a.browserdetect__chrome {
  background-image: url("/images/browserdetect/chrome-64x64.png");
}
.browserdetect__list a.browserdetect__safari {
  background-image: url("/images/browserdetect/safari-64x64.png");
}

/* Z-INDEX */
.formError {
  z-index: 10;
}

.formError .formErrorContent {
  z-index: 10;
}

.formError .formErrorArrow {
  z-index: 10;
}

.ui-dialog .formError {
  z-index: 10;
}

.ui-dialog .formError .formErrorContent {
  z-index: 10;
}

.ui-dialog .formError .formErrorArrow {
  z-index: 10;
}

.inputContainer {
  position: relative;
  float: left;
}

.formError {
  position: absolute;
  top: 300px;
  left: 300px;
  display: block;
  cursor: pointer;
  text-align: center;
}

.formError.inline {
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
}

.ajaxSubmit {
  padding: 20px;
  background: #55ea55;
  border: 1px solid #999;
  display: none;
}

.formError .formErrorContent {
  width: 100%;
  background: #957090;
  border-radius: 4px;
  position: relative;
  color: #fff;
  min-width: 150px;
  font-size: 11px;
  padding: 4px 10px;
}

.formError.inline .formErrorContent {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
}

.greenPopup .formErrorContent {
  background: #957090;
}

.blackPopup .formErrorContent {
  background: #957090;
  color: #FFF;
}

.formError .formErrorArrow {
  width: 15px;
  margin: -2px 0 0 130px;
  position: relative;
}

body[dir=rtl] .formError .formErrorArrow,
body.rtl .formError .formErrorArrow {
  margin: -2px 13px 0 0;
}

.formError .formErrorArrowBottom {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
  margin: 0px 0 0 12px;
  top: 2px;
}

.formError .formErrorArrow div {
  font-size: 0px;
  height: 1px;
  background: #957090;
  margin: 0 auto;
  line-height: 0;
  font-size: 0;
  display: block;
}

.formError .formErrorArrowBottom div {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  -o-box-shadow: none;
}

.greenPopup .formErrorArrow div {
  background: #957090;
}

.blackPopup .formErrorArrow div {
  background: #393939;
  color: #FFF;
}

.formError .formErrorArrow .line10 {
  width: 15px;
  border: none;
}

.formError .formErrorArrow .line9 {
  width: 13px;
  border: none;
}

.formError .formErrorArrow .line8 {
  width: 11px;
}

.formError .formErrorArrow .line7 {
  width: 9px;
}

.formError .formErrorArrow .line6 {
  width: 7px;
}

.formError .formErrorArrow .line5 {
  width: 5px;
}

.formError .formErrorArrow .line4 {
  width: 3px;
}

.formError .formErrorArrow .line3 {
  width: 1px;
  border-top: 0px solid #957090;
  border-right: 2px solid #957090;
  border-bottom: 0px solid #957090;
  display: none;
}

.formError .formErrorArrow .line2 {
  width: 3px;
  border: none;
  background: #957090;
  display: none;
}

.formError .formErrorArrow .line1 {
  width: 1px;
  border: none;
  background: #957090;
  display: none;
}

.hidden {
  display: none !important;
}

.clear {
  clear: both;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.absolute-child {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.vertical-align {
  display: table;
  width: 100%;
  height: 100%;
}

.vertical-align-inner {
  display: table-cell;
  vertical-align: middle;
}

.to-tablet {
  display: none;
}

.to-desktop {
  display: none;
}

/*
 ######    ########   ####  ########   
##    ##   ##     ##   ##   ##     ##  
##         ##     ##   ##   ##     ##  
##   ####  ########    ##   ##     ##  
##    ##   ##   ##     ##   ##     ##  
##    ##   ##    ##    ##   ##     ##  
 ######    ##     ##  ####  ########   
 */
.row {
  display: block;
  *zoom: 1;
  margin: -10px;
  margin: -10px;
  margin: -15px;
}
.row:before, .row:after {
  display: table;
  content: "";
}
.row:after {
  clear: both;
}
.col-1-2,
.col-2-2,
.col-1-3,
.col-2-3,
.col-3-3,
.col-1-4,
.col-2-4,
.col-3-4,
.col-4-4 {
  display: block;
  width: 100%;
  padding: 10px;
  float: left;
  padding: 10px;
  padding: 15px;
}

.col-1-2 {
  width: 50%;
}

.col-1-3 {
  width: 33.3333333333%;
}

.col-2-3 {
  width: 66.6666666667%;
}

.col-1-4 {
  width: 25%;
}

.col-2-4 {
  width: 50%;
}

.col-3-4 {
  width: 75%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
  outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
  outline: none;
  color: #957090;
}

html {
  text-size-adjust: 100%;
}

body {
  background-color: #fff;
  font-weight: 300;
  font-size: 14px;
  font-size: 1.4rem;
  font-family: "Roboto", sans-serif;
  color: #4f3248;
}
body.noscroll {
  overflow: hidden;
}

/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
 */
.editor-text {
  color: #4f3248;
}
.editor-text p {
  line-height: 1.5;
  margin-bottom: 1.5em;
}
.editor-text a {
  color: red;
  display: inline-block;
  border-bottom: 1px solid red;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.no-touch .editor-text a:hover, .no-touch .editor-text a:focus {
  color: #ff3333;
  border-color: #ff3333;
}
.editor-text h1 {
  margin-bottom: 1.5em;
  font-family: "Alice", serif;
  text-transform: uppercase;
  font-size: 20px;
  font-size: 2rem;
  font-weight: 700;
}
.editor-text h2 {
  margin-bottom: 1.5em;
  font-family: "Alice", serif;
  font-size: 16px;
  font-size: 1.6rem;
}
.editor-text h3 {
  margin-bottom: 1em;
  font-weight: 600;
}

* .wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.center-1200-max {
  max-width: 1200px;
  margin: 0 auto;
}

.center-820-max {
  max-width: 820px;
  margin: 0 auto;
  padding: 0 10px;
}

.padding-top-70 {
  padding-top: 50px;
  padding-top: 70px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.none {
  display: none;
  display: block;
}

/* 
##     ##  ########   ##    ##   ##     ##  
###   ###  ##         ###   ##   ##     ##  
#### ####  ##         ####  ##   ##     ##  
## ### ##  ######     ## ## ##   ##     ##  
##     ##  ##         ##  ####   ##     ##  
##     ##  ##         ##   ###   ##     ##  
##     ##  ########   ##    ##    #######   
 */
.comble {
  width: 100%;
  height: 64px;
  height: 110px;
}

.fond-violet-droit-menu {
  position: fixed;
  display: none;
  width: 49%;
  height: 110px;
  top: 0;
  right: 0;
  background-color: #957090;
  z-index: 28;
  display: block;
}

.fond-blanc-droit-menu {
  position: fixed;
  display: none;
  width: 49%;
  height: 110px;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 28;
  display: block;
}

.menu-fixe {
  width: 100%;
  height: 65px;
  position: fixed;
  top: 0;
  z-index: 30;
  box-shadow: 0 1px 2px 1px rgba(79, 50, 72, 0.2);
  background-color: #fff;
  height: 110px;
}

.logo {
  width: 100%;
  height: 65px;
  background-color: #957090;
  font-family: "Alice", serif;
  float: left;
  padding: 10px 20px;
  margin-top: 15px;
  background-color: white;
  width: 240px;
  height: 95px;
  padding: 15px;
}
.logo .link-home {
  color: white;
  display: block;
  width: 190px;
  color: #4f3248;
  width: 250px;
  transition: 0.2s ease-in-out;
}
.logo .link-home:hover {
  color: #34223a;
}
.logo .link-home:hover {
  color: #957090;
}
.logo .logo-docteur {
  font-size: 18px;
  margin: 0;
}
.logo .logo-agathe {
  color: #916B8B;
  font-size: 31px;
  margin-top: 12px;
  margin-left: 42px;
}

.nav {
  display: none;
  white-space: nowrap;
  position: relative;
  display: block;
  width: calc(100% - 240px);
  height: 110px;
  font-weight: 300;
  color: white;
  font-size: 13px;
  background-color: #957090;
  padding-left: 0px;
  padding-top: 25px;
  float: left;
}
.nav:after {
  content: "";
  display: block;
  width: 1000px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #957090;
}
.nav li {
  display: inline-block;
  padding: 0px 5px;
}
.nav .nav-links {
  padding: 0 10px;
  color: white;
  border-radius: 4px;
  background-color: #957090;
  transition: 0.3s ease-in-out;
}
.nav .nav-links:hover {
  background-color: #876483;
}
.nav .nav-main {
  float: right;
  text-align: right;
  text-transform: uppercase;
}
.nav .nav-submain {
  float: right;
  text-align: right;
  margin-bottom: 15px;
  margin-right: 10px;
}
.nav .nav-submain .nav-alt {
  font-size: 13px;
  color: black;
}
.nav .nav-submain .nav-alt:hover {
  text-decoration: underline;
}
.nav .nav-submain .nav-alt.cta-rdv {
  background-color: #fff;
  padding: 5px 10px;
  color: #957090;
  font-weight: bolder;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  border-radius: 2px;
}
.nav .nav-submain .nav-alt.cta-rdv:hover {
  text-decoration: none;
  background-color: #4f3248;
  color: #FFF;
}
.nav .nav-submain li:before {
  display: inline-block;
  content: "|";
  color: black;
  margin-right: 8px;
  opacity: 0.4;
}
.nav .nav-submain li:first-of-type:before {
  display: none;
}

/* 
##     ##  ########   ##    ##   ##     ##       ##     ##   #######   ########   ####  ##         ########   
###   ###  ##         ###   ##   ##     ##       ###   ###  ##     ##  ##     ##   ##   ##         ##         
#### ####  ##         ####  ##   ##     ##       #### ####  ##     ##  ##     ##   ##   ##         ##         
## ### ##  ######     ## ## ##   ##     ##       ## ### ##  ##     ##  ########    ##   ##         ######     
##     ##  ##         ##  ####   ##     ##       ##     ##  ##     ##  ##     ##   ##   ##         ##         
##     ##  ##         ##   ###   ##     ##       ##     ##  ##     ##  ##     ##   ##   ##         ##         
##     ##  ########   ##    ##    #######        ##     ##   #######   ########   ####  ########   ########   
 */
.menu-2 {
  display: block;
  position: absolute;
  z-index: 60;
  display: none;
}
.menu-2 a {
  display: inline-block;
  position: relative;
  text-align: center;
  color: #4f3248;
  text-decoration: none;
  font-size: 16px;
  overflow: hidden;
  top: 5px;
}

.button_container {
  position: fixed;
  top: 20px;
  right: 20px;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.25s ease;
}
.button_container.active .top {
  transform: translateY(11px) translateX(0) rotate(45deg);
  background: #4f3248;
}
.button_container.active .middle {
  opacity: 0;
  background: #4f3248;
}
.button_container.active .bottom {
  transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #4f3248;
}
.button_container span {
  background: #4f3248;
  border: none;
  height: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.35s ease;
  cursor: pointer;
}
.button_container span:nth-of-type(2) {
  top: 11px;
}
.button_container span:nth-of-type(3) {
  top: 22px;
}

.overlay {
  margin-top: 64px;
  position: fixed;
  background: #4f3248;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
}
.overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
}
.overlay.open li {
  animation: fadeInRight 0.5s ease forwards;
  animation-delay: 0.35s;
}
.overlay.open li:nth-of-type(2) {
  animation-delay: 0.4s;
}
.overlay.open li:nth-of-type(3) {
  animation-delay: 0.45s;
}
.overlay.open li:nth-of-type(4) {
  animation-delay: 0.5s;
}
.overlay.open li:nth-of-type(5) {
  animation-delay: 0.55s;
}
.overlay nav {
  position: relative;
  height: 80%;
  top: 50%;
  transform: translateY(-50%);
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 300;
  text-align: center;
}
.overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}
.overlay ul li {
  display: block;
  height: 25%;
  height: 14.2857142857%;
  position: relative;
  opacity: 0;
}
.overlay ul li a {
  padding: 8px 20px;
  display: block;
  position: relative;
  color: white;
  text-decoration: none;
  overflow: hidden;
}
.overlay ul li a:active {
  color: #957090;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
/* 
##     ##  ########      ###     ########   ########   ########   
##     ##  ##           ## ##    ##     ##  ##         ##     ##  
##     ##  ##          ##   ##   ##     ##  ##         ##     ##  
#########  ######     ##     ##  ##     ##  ######     ########   
##     ##  ##         #########  ##     ##  ##         ##   ##    
##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
##     ##  ########   ##     ##  ########   ########   ##     ##  
*/
.header-content {
  position: relative;
  padding-top: 70px;
  width: 100%;
  height: 300px;
  margin: 0 auto;
  background: url("/images/fond-header-mobile.jpg") center center no-repeat;
  background-size: cover;
  padding-top: 100px;
  height: 470px;
  margin: 0 auto;
  background: url("/images/fond-header.jpg") center center no-repeat;
  background-size: cover;
}

.header-content.bandeau {
  position: relative;
  padding-top: 70px;
  width: 100%;
  height: 300px;
  margin: 0 auto;
  background: url("/images/consultation.jpg") center center no-repeat;
  background-size: cover;
  padding-top: 100px;
  height: 470px;
  margin: 0 auto;
  background: url("/images/consultation.jpg") center center no-repeat;
  background-size: cover;
}

.header-title {
  font-size: 28px;
  font-family: "Alice", serif;
  color: white;
  text-align: center;
  padding: 0 200px;
  font-size: 62px;
}

.header-filet {
  width: 100px;
  height: 4px;
  background-color: white;
  margin: 35px auto;
}

.header-sub-title {
  font-size: 22px;
  font-family: "Alice", serif;
  color: white;
  text-align: center;
  font-size: 36px;
}

/*
 ######     #######   ##    ##   ########   ########   ##    ##   ########   
##    ##   ##     ##  ###   ##      ##      ##         ###   ##      ##      
##         ##     ##  ####  ##      ##      ##         ####  ##      ##      
##         ##     ##  ## ## ##      ##      ######     ## ## ##      ##      
##         ##     ##  ##  ####      ##      ##         ##  ####      ##      
##    ##   ##     ##  ##   ###      ##      ##         ##   ###      ##      
 ######     #######   ##    ##      ##      ########   ##    ##      ##      
 */
.title-lev-1 {
  font-family: "Alice", serif;
  color: #4f3248;
  font-size: 32px;
  text-align: center;
  margin-bottom: 50px;
  padding: 60px 0 50px 0;
  border-bottom: 1px solid #4f3248;
  font-size: 45px;
  margin-bottom: 70px;
  padding: 90px 0 70px 0;
}

.title-lev-2 {
  font-family: "Alice", serif;
  color: #34223a;
  font-size: 20px;
  text-align: center;
  padding: 35px 0 20px 0;
  margin: 0;
  font-size: 25px;
  padding: 45px 0 30px 0;
}
.rdv .title-lev-2:first-of-type {
  padding: 0 0 20px 0;
  padding: 0 0 30px 0;
}

.preparer p + p.title-lev-2 {
  padding: 35px 0 30px 0;
  padding: 45px 0 30px 0;
}

.title-lev-3 {
  font-family: "Alice", serif;
  color: #34223a;
  font-size: 18px;
  margin: 0;
  padding: 5px 0;
  font-size: 22px;
}

.title-lev-5 {
  font-family: "Alice", serif;
  color: #4f3248;
  font-size: 32px;
  text-align: center;
  margin-bottom: 50px;
  padding: 0px 0 50px 0;
  border-bottom: 1px solid #4f3248;
  font-size: 45px;
  margin-bottom: 70px;
  padding: 0px 0 70px 0;
}

.sans-titre {
  margin-bottom: 0px;
}

.button {
  display: block;
  width: 250px;
  margin: 0 auto;
  padding: 15px 15px;
  text-align: center;
  color: #4f3248;
  font-weight: 500;
  border-radius: 4px;
  border: solid 1px #957090;
  transition: 0.4s ease-in-out;
}
.button:hover {
  background-color: #4f3248;
  color: white;
}

.button-cta {
  margin-bottom: 1em;
}

.button-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.col {
  padding-top: 10px;
  padding-top: 45px;
}

.content-button {
  padding: 20px 0 120px 0;
  padding: 0 120px 85px 120px;
}

.contenu-texte {
  color: #957090;
  font-size: 16px;
  line-height: 1.4;
}

.texte {
  line-height: 1.5;
}

.contenu-texte-center {
  color: #34223a;
  text-align: center;
  font-size: 16px;
  margin: 0;
  font-size: 18px;
}

.liste li {
  list-style: circle;
  color: #957090;
  margin-left: 12px;
}
.liste li .liste-texte {
  color: #34223a;
}

.categorie {
  overflow: hidden;
}
.categorie .block-categorie {
  position: relative;
  padding: 30px;
  background-color: #f4f4f4;
  -webkit-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.categorie .block-categorie:hover {
  background-color: #957090;
  cursor: pointer;
}
.categorie .block-categorie:hover .title-lev-2, .categorie .block-categorie:hover .texte {
  color: white;
}
.categorie .block-categorie:hover .bottom {
  width: 20%;
}
.categorie .block-categorie:hover a {
  color: white;
}
.categorie .block-categorie .title-lev-2 {
  color: #957090;
  padding-top: 0;
  text-align: left;
}
.categorie .block-categorie .texte {
  color: #000;
  text-transform: 1px;
}
.categorie .block-categorie .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  border: 0;
  width: 60px;
  height: 5px;
  border-radius: 50%;
  transform: translateY(50%);
  background-color: #957090;
  -webkit-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
}
.categorie .col-1-2 {
  padding-left: 0;
  padding-right: 0;
}
.categorie .col-1-2:nth-child(2n+1) {
  padding-right: 10px;
}
.categorie .col-1-2:nth-child(2n+2) {
  padding-left: 10px;
}
.categorie .col-1-2:nth-child(3) {
  clear: both;
}
.categorie .col-1-3:nth-child(1n) {
  padding-left: 0;
}
.categorie .col-1-3:nth-child(5n) {
  padding-right: 0;
}
.categorie .col-1-3 .block-categorie {
  padding: 20px;
}

.picture-container {
  position: relative;
  width: 100%;
}

.picture-container__image {
  width: 66.6%;
}
.picture-container__image img {
  width: 100%;
  height: auto;
}

.picture-container__logo {
  width: 50%;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 30px;
  border: 1px solid;
  border-radius: 10px;
  background-color: #fff;
  width: 33.4%;
  bottom: 0;
  left: auto;
  right: 0;
  border-radius: 0 10px 10px 0;
  border-left: none;
  bottom: 80px;
}
.picture-container__logo img {
  display: block;
  width: 100%;
  height: auto;
}
.picture-container__logo img.smaller {
  width: 70%;
  margin: 0 auto;
}

.block-text--clinique {
  margin-top: -80px;
}

.texte-categorie {
  overflow: hidden;
  margin-top: 100px;
}
.texte-categorie ul {
  list-style-type: inherit;
  padding: 0 40px;
}
.texte-categorie li {
  list-style-type: inherit;
}
.texte-categorie .block-grey {
  background-color: #F2F2F2;
  margin: 20px 0 20px 0;
  border-radius: 10px;
}
.texte-categorie .block-grey .texte-block-grey {
  float: left;
  width: 30%;
  height: auto;
  padding: 25px 25px;
  font-size: 14px;
  font-weight: bold;
  color: black;
}
.texte-categorie .block-grey .texte-block-grey h2 {
  font-size: 18px;
  margin-bottom: 15px;
  color: #9B7796;
}
.texte-categorie .block-grey .texte-block-grey .maps {
  display: block;
  height: auto;
  width: 60%;
  float: right;
}
@media screen and (max-width: 650px) {
  .texte-categorie .block-grey .texte-block-grey {
    font-size: 14px;
    float: none;
    width: auto;
  }
  .texte-categorie .block-grey .texte-block-grey h2 {
    color: #9B7796;
    margin-bottom: 5px;
  }
}
.texte-categorie .button {
  color: #957090;
  width: 100%;
  white-space: nowrap;
}
@media screen and (max-width: 650px) {
  .texte-categorie .button {
    white-space: normal;
  }
}
.texte-categorie .iframe {
  margin-top: 20px;
}
.texte-categorie h3 {
  color: #957090;
  font-size: 20px;
  margin-bottom: 15px;
}
.texte-categorie .block-text {
  position: relative;
  float: right;
  width: 690px;
  max-width: 100%;
  padding: 30px;
  background-color: white;
}
.texte-categorie .block_li_doc {
  margin: 20px auto;
  font-size: 14px;
  list-style-type: none;
}
@media screen and (max-width: 650px) {
  .texte-categorie .block_li_doc {
    margin-top: 20px;
    margin-left: -30px;
    font-size: 12px;
  }
  .texte-categorie .block_li_doc img {
    width: 40px;
    height: 40px;
    padding: 5px;
  }
  .texte-categorie .block_li_doc li {
    margin-top: 10px;
    transition: transform 0.2s ease-in-out;
  }
  .texte-categorie .block_li_doc li:hover {
    transform: scale(0.9);
  }
}
.texte-categorie .block_li_doc img {
  margin-right: 4px;
  margin-top: 2px;
  width: 30px;
  height: 30px;
  padding: 5px;
}

.block_li_centre {
  display: block;
  margin: 0 auto;
  font-size: 12px;
}
.block_li_centre p {
  text-align: center;
}
.block_li_centre ul {
  margin: 0 auto;
}
.block_li_centre li {
  margin: 12px;
  transition: transform 0.2s ease-in-out;
}
.block_li_centre li:hover {
  transform: scale(0.96, 0.96);
}
.block_li_centre img {
  width: 35px;
  height: 35px;
  padding: 5px;
}
@media screen and (min-width: 750px) {
  .block_li_centre {
    width: 800px;
  }
  .block_li_centre p {
    margin-left: 100px;
    text-align: left;
  }
  .block_li_centre ul {
    white-space: normal;
    margin-left: 100px;
  }
}

@media screen and (max-width: 650px) {
  .categorie .col-1-2:nth-child(2n+1) {
    padding-right: 0 !important;
  }
  .categorie .col-1-2:nth-child(2n+2) {
    padding-left: 0 !important;
  }
  .categorie .col-1-3:nth-child(1n) {
    padding-right: 0 !important;
  }
  .categorie .col-1-3:nth-child(5n) {
    padding-left: 0 !important;
  }
  .texte-categorie img {
    width: 100%;
  }
  .texte-categorie .block-text {
    top: 10px;
  }
}
@media screen and (max-width: 1200px) {
  .header-title {
    padding: 0 30px;
  }
}
.home-movie {
  margin-top: 3rem;
  width: 100%;
  aspect-ratio: 16/9;
}
.home-movie iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 
########   ########   ########    ######    ########   ##    ##   ########      ###     ########   ####   #######   ##    ##   
##     ##  ##     ##  ##         ##    ##   ##         ###   ##      ##        ## ##       ##       ##   ##     ##  ###   ##   
##     ##  ##     ##  ##         ##         ##         ####  ##      ##       ##   ##      ##       ##   ##     ##  ####  ##   
########   ########   ######      ######    ######     ## ## ##      ##      ##     ##     ##       ##   ##     ##  ## ## ##   
##         ##   ##    ##               ##   ##         ##  ####      ##      #########     ##       ##   ##     ##  ##  ####   
##         ##    ##   ##         ##    ##   ##         ##   ###      ##      ##     ##     ##       ##   ##     ##  ##   ###   
##         ##     ##  ########    ######    ########   ##    ##      ##      ##     ##     ##      ####   #######   ##    ##   
 */
.photo-presentation {
  max-width: 300px;
  margin: 0;
  margin: 0 0 0 50px;
}

/*
 ######     #######   ##    ##   ########      ###      ######    ########   
##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
##         ##     ##  ##  ####      ##      #########  ##            ##      
##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
 ######     #######   ##    ##      ##      ##     ##   ######       ##      
 */
.contact a {
  margin: 0 0 1em 0;
  display: inline-block;
}

.options {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 5em;
}
.options .option {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 200px;
}

.form-champ {
  width: 100%;
  margin-bottom: 5px;
  border-radius: 2px;
  border: 1px solid #957090;
  padding: 8px 5px;
  background-color: #f7f4f6;
  font-size: 12px;
}

textarea.form-champ {
  height: 150px;
}

.formulaire {
  margin-top: 30px;
  padding: 30px 10px;
  border-top: 1px solid #34223a;
  border-bottom: 1px solid #34223a;
  margin-top: 0;
  padding: 0 10px;
  border-top: 0;
  border-bottom: 0;
}

a.envoyer {
  color: white;
}

button.envoyer {
  color: white;
}

.envoyer {
  display: inline-block;
  background-color: #957090;
  padding: 5px 10px;
  border: 0;
  border-radius: 2px;
  margin-top: 8px;
  transition: 0.4s ease-in-out;
}
.envoyer:hover {
  background-color: #4f3248;
}

.form-label {
  color: #957090;
  font-size: 14px;
}

.obligatoire {
  text-align: right;
  font-size: 12px;
}

.map {
  margin-top: 50px;
  width: 100%;
  height: 250px;
  height: 400px;
  margin-top: 0;
}

.envoi-content {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 5000;
}

.envoi-fond {
  display: block;
  width: 100%;
  height: 100%;
  background-color: #957090;
  opacity: 0.95;
}

.envoi-message {
  position: fixed;
  width: 290px;
  padding: 30px 0 30px 0;
  border-radius: 6px;
  text-align: center;
  background-color: white;
  color: #34223a;
  left: 50%;
  margin-left: -150px;
  top: 40%;
  border: 3px solid #4f3248;
}

.envoi-retour {
  color: #34223a;
  background-color: white;
  position: fixed;
  z-index: 6000;
  top: 40%;
  left: 50%;
  padding: 8px;
  width: 80px;
  text-align: center;
  margin-left: 60px;
  margin-top: 90px;
  border-radius: 4px;
  border: 2px solid #4f3248;
  font-size: 12px;
  transition: 0.2s;
  display: none;
  font-weight: 500;
}
.envoi-retour:hover {
  background-color: #4f3248;
  color: white;
}

/* 
########    #######    #######   ########   ########   ########   
##         ##     ##  ##     ##     ##      ##         ##     ##  
##         ##     ##  ##     ##     ##      ##         ##     ##  
######     ##     ##  ##     ##     ##      ######     ########   
##         ##     ##  ##     ##     ##      ##         ##   ##    
##         ##     ##  ##     ##     ##      ##         ##    ##   
##          #######    #######      ##      ########   ##     ##  
*/
.footer {
  *zoom: 1;
  margin-top: 100px;
  width: 100%;
  background-color: #957090;
  color: white;
}
.footer:before, .footer:after {
  display: table;
  content: "";
}
.footer:after {
  clear: both;
}
.footer .footer-contact {
  width: 100%;
  float: left;
  padding: 30px 15px 0 15px;
  width: 335px;
}
.footer .footer-contact p:last-child {
  padding-top: 20px;
}
.footer .footer-consultation {
  width: 100%;
  float: left;
  padding: 40px 15px;
  text-align: left;
  line-height: 1.3;
  width: 475px;
  float: right;
  text-align: right;
}
.footer .footer-consultation p:first-child {
  font-weight: 500;
  font-size: 17px;
}

.footer-docteur {
  font-style: 18px;
  font-family: "Alice", serif;
  margin: 0;
}

.footer-agathe {
  font-size: 26px;
  margin-top: -6px;
  font-family: "Alice", serif;
  margin: 0;
}

.footer-coordonnees {
  padding-top: 20px;
}

.footer-mailto {
  color: white;
}
.footer-mailto:hover {
  color: #34223a;
}

.checkbox-container {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
.checkbox-container input {
  margin-top: 2px;
}