/* Z-INDEX */
 .formError { z-index: 10; }
    .formError .formErrorContent { z-index: 10; }
    .formError .formErrorArrow { z-index: 10; }

    .ui-dialog .formError { z-index: 10; }
    .ui-dialog .formError .formErrorContent { z-index: 10; }
    .ui-dialog .formError .formErrorArrow { z-index: 10; }

.inputContainer {
	position: relative;
	float: left;
}

.formError {
	position: absolute;
	top: 300px;
	left: 300px;
	display: block;
	cursor: pointer;
	text-align: center;
}

.formError.inline {
	position: relative;
	top: 0;
	left: 0;
	display: inline-block;
}

.ajaxSubmit {
	padding: 20px;
	background: #55ea55;
	border: 1px solid #999;
	display: none;
}

.formError .formErrorContent {
	width: 100%;
	background: $color-2;
	border-radius: 4px;
	position:relative;
	color: #fff;
	min-width: 150px;
	font-size: 11px;
	padding: 4px 10px;
}

.formError.inline .formErrorContent {
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-o-box-shadow: none;
}

.greenPopup .formErrorContent {
	background: $color-2;
}

.blackPopup .formErrorContent {
	background: $color-2;
	color: #FFF;
}

.formError .formErrorArrow {
	width: 15px;
	margin: -2px 0 0 130px;
	position:relative;
}
body[dir='rtl'] .formError .formErrorArrow,
body.rtl .formError .formErrorArrow {
	margin: -2px 13px 0 0;
}

.formError .formErrorArrowBottom {
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-o-box-shadow: none;
	margin: 0px 0 0 12px;
	top:2px;
}

.formError .formErrorArrow div {
	//border-left: 2px solid #ddd;
	//border-right: 2px solid #ddd;
	//box-shadow: 0 2px 3px #444;
	//-moz-box-shadow: 0 2px 3px #444;
	//-webkit-box-shadow: 0 2px 3px #444;
	//-o-box-shadow: 0 2px 3px #444;
	font-size: 0px;
	height: 1px;
	background: $color-2;
	margin: 0 auto;
	line-height: 0;
	font-size: 0;
	display: block;
}

.formError .formErrorArrowBottom div {
	box-shadow: none;
	-moz-box-shadow: none;
	-webkit-box-shadow: none;
	-o-box-shadow: none;
}

.greenPopup .formErrorArrow div {
	background: $color-2;
}

.blackPopup .formErrorArrow div {
	background: #393939;
	color: #FFF;
}

.formError .formErrorArrow .line10 {
	width: 15px;
	border: none;
}

.formError .formErrorArrow .line9 {
	width: 13px;
	border: none;
}

.formError .formErrorArrow .line8 {
	width: 11px;
}

.formError .formErrorArrow .line7 {
	width: 9px;
}

.formError .formErrorArrow .line6 {
	width: 7px;
}

.formError .formErrorArrow .line5 {
	width: 5px;
}

.formError .formErrorArrow .line4 {
	width: 3px;
}

.formError .formErrorArrow .line3 {
	width: 1px;
	border-top: 0px solid $color-2;
	border-right: 2px solid $color-2;
	border-bottom: 0px solid $color-2;
	display: none;
}

.formError .formErrorArrow .line2 {
	width: 3px;
	border: none;
	background: $color-2;
	display: none;
}

.formError .formErrorArrow .line1 {
	width: 1px;
	border: none;
	background: $color-2;
	display: none;
}

// .formError, .formErrorContent, .formErrorArrow{
// 	position:absolute;
// 	left:-118px;
// 	top:26px;
// }