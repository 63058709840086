html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
	outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
	outline: none;
	color: $color-2;
}

html {
	text-size-adjust: 100%;

}

body {
	background-color: $color-bg;
	font-weight: 300;
	@include font-size(14);
	font-family: $font-base;
	color: $color-text;

	&.noscroll {
		overflow: hidden;
	}
}

/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
 */

.editor-text {
	color: $color-text;

	p {
		line-height: 1.5;
		margin-bottom: 1.5em;
	}

	a {
		color: $color-link;
		display: inline-block;
		border-bottom: 1px solid $color-link;

		transition: color 0.2s ease-in-out,
		border-color 0.2s ease-in-out;

		.no-touch & {
			&:hover, &:focus {
				color: lighten($color-link, 10%);
				border-color: lighten($color-link, 10%);
			}
		}
	}

	h1 {
		margin-bottom: 1.5em;
		font-family: $font-alt;
		text-transform: uppercase;
		@include font-size(20);
		font-weight: 700;
	}

	h2 {
		margin-bottom: 1.5em;
		font-family: $font-alt;
		@include font-size(16);
	}

	h3 {
		margin-bottom: 1em;
		font-weight: 600;
	}
}

*
	/*
	 ######     #######   ##     ##  ##     ##  ##     ##  ##    ##
	##    ##   ##     ##  ###   ###  ###   ###  ##     ##  ###   ##
	##         ##     ##  #### ####  #### ####  ##     ##  ####  ##
	##         ##     ##  ## ### ##  ## ### ##  ##     ##  ## ## ##
	##         ##     ##  ##     ##  ##     ##  ##     ##  ##  ####
	##    ##   ##     ##  ##     ##  ##     ##  ##     ##  ##   ###
	 ######     #######   ##     ##  ##     ##   #######   ##    ##
	 */
.wrapper {
	max-width: 1200px;
	margin: 0 auto;
}

.center-1200-max {
	max-width: 1200px;
	margin: 0 auto;
}

.center-820-max {
	max-width: 820px;
	margin: 0 auto;
	padding: 0 10px;
}

.padding-top-70 {
	padding-top: 50px;
	@include mq($from: tablet) {
		padding-top: 70px;
	}
}

.padding-bottom-20 {
	padding-bottom: 20px;
}

.none {
	display: none;
	@include mq($from: tablet) {
		display: block;
	}
}

/* 
##     ##  ########   ##    ##   ##     ##  
###   ###  ##         ###   ##   ##     ##  
#### ####  ##         ####  ##   ##     ##  
## ### ##  ######     ## ## ##   ##     ##  
##     ##  ##         ##  ####   ##     ##  
##     ##  ##         ##   ###   ##     ##  
##     ##  ########   ##    ##    #######   
 */

.comble {
	width: 100%;
	height: 64px;
	@include mq($from: desktop) {
		height: 110px;
	}
}

.fond-violet-droit-menu {
	position: fixed;
	display: none;
	width: 49%;
	height: 110px;
	top: 0;
	right: 0;
	background-color: $color-2;
	z-index: 28;
	@include mq($from: desktop) {
		display: block;
	}
}

.fond-blanc-droit-menu {
	position: fixed;
	display: none;
	width: 49%;
	height: 110px;
	top: 0;
	left: 0;
	background-color: $color-3;
	z-index: 28;
	@include mq($from: desktop) {
		display: block;
	}
}

.menu-fixe {
	width: 100%;
	height: 65px;
	position: fixed;
	top: 0;
	z-index: 30;
	box-shadow: 0 1px 2px 1px rgba($color-1, 0.2);
	@include mq($from: desktop) {
		background-color: #fff;
		height: 110px;
	}

}

.logo {
	width: 100%;
	height: 65px;
	background-color: $color-2;
	font-family: $font-alt;
	float: left;
	padding: 10px 20px;
	@include mq($from: desktop) {
		margin-top: 15px;
		background-color: $color-3;
		width: 240px;
		height: 95px;
		padding: 15px;
	}

	@include mq($from: wide) {
		// width:285px;
		height: 75px;
		padding: 10px 0;
	}

	.link-home {
		color: $color-3;
		display: block;
		width: 190px;

		&:hover {
			color: $color-4;
		}

		@include mq($from: desktop) {
			color: $color-1;
			width: 250px;
			transition: 0.2s ease-in-out;
			&:hover {
				color: $color-2;
			}
		}
	}

	.logo-docteur {
		font-size: 18px;
		margin: 0;
		@include mq($from: wide) {
			font-size: 21px;
		}
	}

	.logo-agathe {
		color: #916B8B;
		font-size: 31px;
		margin-top: 12px;
		margin-left: 42px;
		@include mq($from: wide) {
			font-size: 30px;
			margin-top: 12px;
		}
	}
}

.nav {
	display: none;
	white-space: nowrap;
	position: relative;

	&:after {
		content: "";
		display: block;
		width: 1000px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 100%;

		background-color: $color-2;

	}

	@include mq($from: desktop) {
		display: block;
		width: calc(100% - 240px);
		height: 110px;
		font-weight: 300;
		color: $color-3;
		font-size: 13px;
		background-color: $color-2;
		padding-left: 0px;
		padding-top: 25px;
		float: left;
	}

	@include mq($from: wide) {
		// width: calc(100% - 285px);
		font-size: 15px;
		padding-left: 60px;

		.nav-main .nav-submain {
			margin-left: 10px;
		}
	}

	li {
		display: inline-block;
		padding: 0px 5px;
	}

	.nav-links {
		padding: 0 10px;
		color: $color-3;
		border-radius: 4px;
		background-color: $color-2;
		transition: 0.3s ease-in-out;

		&:hover {
			background-color: darken($color-2, 5%);
		}
	}

	.nav-main {
		float: right;
		text-align: right;
		text-transform: uppercase;
	}

	.nav-submain {
		float: right;
		text-align: right;

		@include mq($from: desktop) {
			margin-bottom: 15px;
			margin-right: 10px;
		}

		.nav-alt {
			font-size: 13px;
			color: black;

			&:hover {
				text-decoration: underline;
			}

			&.cta-rdv {
				background-color: #fff;
				padding: 5px 10px;
				color: $color-2;
				font-weight: bolder;
				transition: background-color .3s ease-in-out, color .3s ease-in-out;
				border-radius: 2px;

				&:hover {
					text-decoration: none;
					background-color: #4f3248;
					color: #FFF;
				}
			}
		}

		li {
			&:before {
				display: inline-block;
				content: "|";
				color: black;
				margin-right: 8px;
				opacity: 0.4;
			}

			&:first-of-type {
				&:before {
					display: none;
				}
			}
		}
	}
}


/* 
##     ##  ########   ##    ##   ##     ##       ##     ##   #######   ########   ####  ##         ########   
###   ###  ##         ###   ##   ##     ##       ###   ###  ##     ##  ##     ##   ##   ##         ##         
#### ####  ##         ####  ##   ##     ##       #### ####  ##     ##  ##     ##   ##   ##         ##         
## ### ##  ######     ## ## ##   ##     ##       ## ### ##  ##     ##  ########    ##   ##         ######     
##     ##  ##         ##  ####   ##     ##       ##     ##  ##     ##  ##     ##   ##   ##         ##         
##     ##  ##         ##   ###   ##     ##       ##     ##  ##     ##  ##     ##   ##   ##         ##         
##     ##  ########   ##    ##    #######        ##     ##   #######   ########   ####  ########   ########   
 */

.menu-2 {
	display: block;
	position: absolute;
	z-index: 60;
	@include mq($from: desktop) {
		display: none;
	}

	a {
		display: inline-block;
		position: relative;
		text-align: center;
		color: $color-1;
		text-decoration: none;
		font-size: 16px;
		overflow: hidden;
		top: 5px;
	}
}

.button_container {
	position: fixed;
	top: 20px;
	right: 20px;
	height: 27px;
	width: 35px;
	cursor: pointer;
	z-index: 100;
	transition: opacity .25s ease;

	&.active {
		.top {
			transform: translateY(11px) translateX(0) rotate(45deg);
			background: $color-1;
		}

		.middle {
			opacity: 0;
			background: $color-1;
		}

		.bottom {
			transform: translateY(-11px) translateX(0) rotate(-45deg);
			background: $color-1;
		}
	}

	span {
		background: $color-1;
		border: none;
		height: 5px;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		transition: all .35s ease;
		cursor: pointer;

		&:nth-of-type(2) {
			top: 11px;
		}

		&:nth-of-type(3) {
			top: 22px;
		}
	}
}

.overlay {
	margin-top: 64px;
	position: fixed;
	background: $color-1;
	top: 0;
	left: 0;
	width: 100%;
	height: 0%;
	opacity: 0;
	visibility: hidden;
	transition: opacity .35s, visibility .35s, height .35s;
	overflow: hidden;

	&.open {
		opacity: 1;
		visibility: visible;
		height: 100%;

		li {
			animation: fadeInRight .5s ease forwards;
			animation-delay: .35s;

			&:nth-of-type(2) {
				animation-delay: .40s;
			}

			&:nth-of-type(3) {
				animation-delay: .45s;
			}

			&:nth-of-type(4) {
				animation-delay: .50s;
			}

			&:nth-of-type(5) {
				animation-delay: .55s;
			}
		}
	}

	nav {
		position: relative;
		height: 80%;
		top: 50%;
		transform: translateY(-50%);
		text-transform: uppercase;
		font-size: 50px;
		font-weight: 300;
		text-align: center;
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0 auto;
		display: inline-block;
		position: relative;
		height: 100%;

		li {
			display: block;
			height: 25%;
			height: calc(100% / 7);
			position: relative;
			opacity: 0;

			a {
				padding: 8px 20px;
				display: block;
				position: relative;
				color: $color-3;
				text-decoration: none;
				overflow: hidden;

				&:active {
					color: $color-2;
				}
			}
		}
	}
}

@keyframes fadeInRight {
	0% {
		opacity: 0;
		left: 20%;
	}
	100% {
		opacity: 1;
		left: 0;
	}
}


/* 
##     ##  ########      ###     ########   ########   ########   
##     ##  ##           ## ##    ##     ##  ##         ##     ##  
##     ##  ##          ##   ##   ##     ##  ##         ##     ##  
#########  ######     ##     ##  ##     ##  ######     ########   
##     ##  ##         #########  ##     ##  ##         ##   ##    
##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
##     ##  ########   ##     ##  ########   ########   ##     ##  
*/

.header-content {
	position: relative;
	padding-top: 70px;
	width: 100%;
	height: 300px;
	margin: 0 auto;
	background: url('/images/fond-header-mobile.jpg') center center no-repeat;
	background-size: cover;

	@include mq($from: tablet) {
		padding-top: 100px;
		height: 470px;
		margin: 0 auto;
		background: url('/images/fond-header.jpg') center center no-repeat;
		background-size: cover;
	}
}

.header-content.bandeau {
	position: relative;
	padding-top: 70px;
	width: 100%;
	height: 300px;
	margin: 0 auto;
	background: url('/images/consultation.jpg') center center no-repeat;
	background-size: cover;

	@include mq($from: tablet) {
		padding-top: 100px;
		height: 470px;
		margin: 0 auto;
		background: url('/images/consultation.jpg') center center no-repeat;
		background-size: cover;
	}
}


.header-title {
	font-size: 28px;
	font-family: $font-alt;
	color: $color-3;
	text-align: center;
	padding: 0 200px;
	@include mq($from: tablet) {
		font-size: 62px;
	}
}

.header-filet {
	width: 100px;
	height: 4px;
	background-color: $color-3;
	margin: 35px auto;
}

.header-sub-title {
	font-size: 22px;
	font-family: $font-alt;
	color: $color-3;
	text-align: center;
	@include mq($from: tablet) {
		font-size: 36px;
	}
}


/*
 ######     #######   ##    ##   ########   ########   ##    ##   ########   
##    ##   ##     ##  ###   ##      ##      ##         ###   ##      ##      
##         ##     ##  ####  ##      ##      ##         ####  ##      ##      
##         ##     ##  ## ## ##      ##      ######     ## ## ##      ##      
##         ##     ##  ##  ####      ##      ##         ##  ####      ##      
##    ##   ##     ##  ##   ###      ##      ##         ##   ###      ##      
 ######     #######   ##    ##      ##      ########   ##    ##      ##      
 */


.title-lev-1 {
	font-family: $font-alt;
	color: $color-1;
	font-size: 32px;
	text-align: center;
	margin-bottom: 50px;
	padding: 60px 0 50px 0;
	border-bottom: 1px solid $color-1;

	@include mq($from: tablet) {
		font-size: 45px;
		margin-bottom: 70px;
		padding: 90px 0 70px 0;
	}
}

.title-lev-2 {
	font-family: $font-alt;
	color: $color-4;
	font-size: 20px;
	text-align: center;
	padding: 35px 0 20px 0;
	margin: 0;

	@include mq($from: tablet) {
		font-size: 25px;
		padding: 45px 0 30px 0;
	}

	.rdv & {
		&:first-of-type {
			padding: 0 0 20px 0;

			@include mq($from: tablet) {
				padding: 0 0 30px 0;
			}
		}
	}
}

p +
p.title-lev-2 {
	.preparer & {
		padding: 35px 0 30px 0;

		@include mq($from: tablet) {
			padding: 45px 0 30px 0;
		}
	}
}

.title-lev-3 {
	font-family: $font-alt;
	color: $color-4;
	font-size: 18px;
	margin: 0;
	padding: 5px 0;

	@include mq($from: tablet) {
		font-size: 22px;
	}
}

.title-lev-5 {
	font-family: $font-alt;
	color: $color-1;
	font-size: 32px;
	text-align: center;
	margin-bottom: 50px;
	padding: 0px 0 50px 0;
	border-bottom: 1px solid $color-1;

	@include mq($from: tablet) {
		font-size: 45px;
		margin-bottom: 70px;
		padding: 0px 0 70px 0;
	}
}

.sans-titre {
	margin-bottom: 0px;
}

.button {
	display: block;
	width: 250px;
	margin: 0 auto;
	padding: 15px 15px;
	text-align: center;
	color: $color-1;
	font-weight: 500;
	border-radius: 4px;
	border: solid 1px $color-2;
	transition: 0.4s ease-in-out;

	&:hover {
		background-color: $color-1;
		color: $color-3;
	}
}

.button-cta {
	margin-bottom: 1em;
}

.button-disabled {
	pointer-events: none;
	opacity: 0.5;
}


.col {
	padding-top: 10px;
	@include mq($from: tablet) {
		padding-top: 45px;
	}
}

.content-button {
	padding: 20px 0 120px 0;
	@include mq($from: tablet) {
		padding: 0 120px 85px 120px;
	}
}

.contenu-texte {
	color: $color-2;
	font-size: 16px;
	line-height: 1.4;
}

.texte {
	line-height: 1.5;
}

.contenu-texte-center {
	color: $color-4;
	text-align: center;
	font-size: 16px;
	margin: 0;
	@include mq($from: tablet) {
		font-size: 18px;
	}
}

.liste li {
	list-style: circle;
	color: $color-2;
	margin-left: 12px;

	.liste-texte {
		color: $color-4;
	}
}

.categorie {
	overflow: hidden;

	.block-categorie {
		position: relative;
		padding: 30px;
		background-color: $color-bg-2;
		-webkit-transition: .4s ease-in-out;
		-o-transition: .4s ease-in-out;
		transition: .4s ease-in-out;

		&:hover {
			background-color: $color-2;
			cursor: pointer;

			.title-lev-2, .texte {
				color: white;
			}

			.bottom {
				width: 20%;
			}

			a {
				color: white;
			}
		}

		.title-lev-2 {
			color: $color-2;
			padding-top: 0;
			text-align: left;
		}

		.texte {
			color: $black;
			text-transform: 1px;
		}

		.bottom {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0 auto;
			border: 0;
			width: 60px;
			height: 5px;
			border-radius: 50%;
			transform: translateY(50%);
			background-color: $color-2;
			-webkit-transition: .4s ease-in-out;
			-o-transition: .4s ease-in-out;
			transition: .4s ease-in-out;
		}
	}

	.col-1-2 {
		padding-left: 0;
		padding-right: 0;

		&:nth-child(2n+1) {
			padding-right: 10px;
		}

		&:nth-child(2n+2) {
			padding-left: 10px;
		}

		&:nth-child(3) {
			clear: both;
		}

	}

	.col-1-3 {
		&:nth-child(1n) {
			padding-left: 0;
		}

		&:nth-child(5n) {
			padding-right: 0;
		}

		.block-categorie {
			padding: 20px;
		}
	}
}

.picture-container {
	position: relative;
	width: 100%;

	@include mq($until: mobileLandscape) {
		padding-bottom: 40px;
	}

}

.picture-container__image {
	@include mq($from: mobileLandscape) {
		width: 66.6%;
	}

	img {
		width: 100%;
		height: auto;
	}
}

.picture-container__logo {
	width: 50%;
	padding: 20px;
	position: absolute;
	bottom: 0;
	left: 30px;

	border: 1px solid;
	border-radius: 10px;
	background-color: #fff;

	img {
		display: block;
		width: 100%;
		height: auto;

		&.smaller {
			width: 70%;
			margin: 0 auto;
		}
	}

	@include mq($from: mobileLandscape) {
		width: 33.4%;
		bottom: 0;
		left: auto;
		right: 0;
		border-radius: 0 10px 10px 0;
		border-left: none;
	}


	@include mq($from: 800px) {
		bottom: 80px;
	}
}

.block-text--clinique {
	@include mq($from: 800px) {
		margin-top: -80px;
	}
}

.texte-categorie {
	overflow: hidden;
	margin-top: 100px;

	ul {
		list-style-type: inherit;
		padding: 0 40px;
	}

	li {
		list-style-type: inherit;
	}


	.block-grey {
		background-color: #F2F2F2;
		margin: 20px 0 20px 0;
		border-radius: 10px;

		.texte-block-grey {
			float: left;
			width: 30%;
			height: auto;
			padding: 25px 25px;
			font-size: 14px;
			font-weight: bold;
			color: black;

			h2 {
				font-size: 18px;
				margin-bottom: 15px;
				color: #9B7796;
			}

			.maps {
				display: block;
				height: auto;
				width: 60%;
				float: right;
			}
		}

		@media screen and (max-width: 650px) {
			.texte-block-grey {
				font-size: 14px;
				float: none;
				width: auto;

				h2 {
					color: #9B7796;
					margin-bottom: 5px;
				}
			}
		}
	}

	.button {
		@media screen and (max-width: 650px) {
			white-space: normal;
		}
		color: #957090;
		width: 100%;
		white-space: nowrap;
	}

	.iframe {
		margin-top: 20px;
	}

	h3 {
		color: $color-2;
		font-size: 20px;
		margin-bottom: 15px;
	}

	.block-text {
		position: relative;
		float: right;
		width: 690px;
		max-width: 100%;
		padding: 30px;
		background-color: white;
	}

	.block_li_doc {
		@media screen and (max-width: 650px) {
			margin-top: 20px;
			margin-left: -30px;
			font-size: 12px;
			img {
				width: 40px;
				height: 40px;
				padding: 5px;
			}
			li {
				margin-top: 10px;
				transition: transform 0.2s ease-in-out;

				&:hover {
					transform: scale(0.9);
				}
			}
		}
		margin: 20px auto;
		font-size: 14px;

		img {
			margin-right: 4px;
			margin-top: 2px;
			width: 30px;
			height: 30px;
			padding: 5px;
		}

		list-style-type: none;
	}
}

.block_li_centre {
	display: block;
	margin: 0 auto;
	font-size: 12px;

	p {
		text-align: center;
	}

	ul {
		margin: 0 auto;
	}

	li {
		margin: 12px;
		transition: transform 0.2s ease-in-out;

		&:hover {
			transform: scale(0.96, 0.96);
		}
	}

	img {
		width: 35px;
		height: 35px;
		padding: 5px;
	}

	@media screen and (min-width: 750px) {
		width: 800px;
		p {
			margin-left: 100px;
			text-align: left;
		}
		ul {
			white-space: normal;
			margin-left: 100px;
		}
	}
}

@media screen and (max-width: 650px) {
	.categorie {
		.col-1-2 {
			&:nth-child(2n+1) {
				padding-right: 0 !important;
			}

			&:nth-child(2n+2) {
				padding-left: 0 !important;
			}
		}

		.col-1-3 {
			&:nth-child(1n) {
				padding-right: 0 !important;
			}

			&:nth-child(5n) {
				padding-left: 0 !important;
			}
		}
	}
	.texte-categorie {
		img {
			width: 100%;
		}

		.block-text {
			top: 10px;
		}
	}
}

@media screen and (max-width: 1200px) {
	.header-title {
		padding: 0 30px;
	}
}

.home-movie{
	margin-top: 3rem;
	width: 100%;
	aspect-ratio: 16/9;
	iframe{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}


/* 
########   ########   ########    ######    ########   ##    ##   ########      ###     ########   ####   #######   ##    ##   
##     ##  ##     ##  ##         ##    ##   ##         ###   ##      ##        ## ##       ##       ##   ##     ##  ###   ##   
##     ##  ##     ##  ##         ##         ##         ####  ##      ##       ##   ##      ##       ##   ##     ##  ####  ##   
########   ########   ######      ######    ######     ## ## ##      ##      ##     ##     ##       ##   ##     ##  ## ## ##   
##         ##   ##    ##               ##   ##         ##  ####      ##      #########     ##       ##   ##     ##  ##  ####   
##         ##    ##   ##         ##    ##   ##         ##   ###      ##      ##     ##     ##       ##   ##     ##  ##   ###   
##         ##     ##  ########    ######    ########   ##    ##      ##      ##     ##     ##      ####   #######   ##    ##   
 */

.photo-presentation {
	max-width: 300px;
	margin: 0;

	@include mq($from: tablet) {
		margin: 0 0 0 50px;
	}
}


/*
 ######     #######   ##    ##   ########      ###      ######    ########   
##    ##   ##     ##  ###   ##      ##        ## ##    ##    ##      ##      
##         ##     ##  ####  ##      ##       ##   ##   ##            ##      
##         ##     ##  ## ## ##      ##      ##     ##  ##            ##      
##         ##     ##  ##  ####      ##      #########  ##            ##      
##    ##   ##     ##  ##   ###      ##      ##     ##  ##    ##      ##      
 ######     #######   ##    ##      ##      ##     ##   ######       ##      
 */

.contact {
	a {
		margin: 0 0 1em 0;
		display: inline-block;
	}
}

.options {
	display: flex;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	gap: 5em;

	.option {
		width: 50%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		min-height: 200px;
	}
}

.form-champ {
	width: 100%;
	margin-bottom: 5px;
	border-radius: 2px;
	border: 1px solid $color-2;
	padding: 8px 5px;
	background-color: lighten($color-2, 45%);
	font-size: 12px;
}

textarea.form-champ {
	height: 150px;
}

.formulaire {
	margin-top: 30px;
	padding: 30px 10px;
	border-top: 1px solid $color-4;
	border-bottom: 1px solid $color-4;
	@include mq($from: tablet) {
		margin-top: 0;
		padding: 0 10px;
		border-top: 0;
		border-bottom: 0;
	}
}

a.envoyer {
	color: white;
}

button.envoyer {
	color: white;
}

.envoyer {
	display: inline-block;
	background-color: $color-2;
	padding: 5px 10px;
	border: 0;
	border-radius: 2px;
	margin-top: 8px;
	transition: 0.4s ease-in-out;

	&:hover {
		background-color: $color-1;
	}
}

.form-label {
	color: $color-2;
	font-size: 14px;
}

.obligatoire {
	text-align: right;
	font-size: 12px;
}

.map {
	margin-top: 50px;
	width: 100%;
	height: 250px;

	@include mq($from: tablet) {
		height: 400px;
		margin-top: 0;
	}
}

.envoi-content {
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 5000;
}

.envoi-fond {
	display: block;
	width: 100%;
	height: 100%;
	background-color: $color-2;
	opacity: 0.95;
}

.envoi-message {
	position: fixed;
	width: 290px;
	padding: 30px 0 30px 0;
	border-radius: 6px;
	text-align: center;
	background-color: $color-3;
	color: $color-4;
	left: 50%;
	margin-left: -150px;
	top: 40%;
	border: 3px solid $color-1;
}

.envoi-retour {
	color: $color-4;
	background-color: $color-3;
	position: fixed;
	z-index: 6000;
	top: 40%;
	left: 50%;
	padding: 8px;
	width: 80px;
	text-align: center;
	margin-left: 60px;
	margin-top: 90px;
	border-radius: 4px;
	border: 2px solid $color-1;
	font-size: 12px;
	transition: 0.2s;
	display: none;
	font-weight: 500;

	&:hover {
		background-color: $color-1;
		color: $color-3;
	}
}


/* 
########    #######    #######   ########   ########   ########   
##         ##     ##  ##     ##     ##      ##         ##     ##  
##         ##     ##  ##     ##     ##      ##         ##     ##  
######     ##     ##  ##     ##     ##      ######     ########   
##         ##     ##  ##     ##     ##      ##         ##   ##    
##         ##     ##  ##     ##     ##      ##         ##    ##   
##          #######    #######      ##      ########   ##     ##  
*/

.footer {
	@include clearfix;
	margin-top: 100px;
	width: 100%;
	background-color: $color-2;
	color: $color-3;

	.footer-contact {
		width: 100%;
		float: left;
		padding: 30px 15px 0 15px;

		p:last-child {
			padding-top: 20px;
		}

		@include mq($from: tablet) {
			width: 335px;
		}
	}

	.footer-consultation {
		width: 100%;
		float: left;
		padding: 40px 15px;
		text-align: left;
		line-height: 1.3;

		p:first-child {
			font-weight: 500;
			font-size: 17px;
		}

		@include mq($from: tablet) {
			width: 475px;
			float: right;
			text-align: right;
		}
	}
}

.footer-docteur {
	font-style: 18px;
	font-family: $font-alt;
	margin: 0;
}

.footer-agathe {
	font-size: 26px;
	margin-top: -6px;
	font-family: $font-alt;
	margin: 0;
}

.footer-coordonnees {
	padding-top: 20px;
}

.footer-mailto {
	color: $color-3;

	&:hover {
		color: $color-4;
	}
}


.checkbox-container {
	display: flex;
	align-items: flex-start;
	gap: 5px;

	input {
		margin-top: 2px;
	}
}