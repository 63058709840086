html { font-size: 62.5%; }

$font-base: 'Roboto', sans-serif;
$font-alt:  'Alice', serif;
$image-path: '/images/';

$color-1: #4f3248; //violet foncé
$color-2: #957090; //violet clair
$color-3: white;   //blanc
$color-4: #34223a; //violet très foncé

$color-bg:          #fff;
$color-bg-2:        #f4f4f4;
$color-text:        $color-1;
$color-text-light:  #fff;

$color-cancel: #bc2121;

$color-link: red;
$color-link-hover: blue;

$color-border: #ddd;

$color-facebook:    #3b5998;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;

$white: #fff;
$black: #000;

$grid-gutter-base:    20px * 0.5;
$grid-gutter-tablet:  20px * 0.5;
$grid-gutter-desktop: 30px * 0.5;
$grid-gutter-wide:    30px * 0.5;